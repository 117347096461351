import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';

function makeQueryClient() {
    const queryCache = new QueryCache({
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const mutationCache = new MutationCache({
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    return new QueryClient({
        queryCache,
        mutationCache,
        defaultOptions: {
            queries: {
                staleTime: typeof window !== 'undefined' ? 60 * 1000 : 10 * 1000,
            },
        },
    });
}

let queryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
    if (!queryClient) queryClient = makeQueryClient();
    return queryClient;
}
