'use client';

import { QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { createStore, Provider as JotaiStoreProvider } from 'jotai';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import { QerkoLoader } from 'restaurant-v3/src/components/QerkoLoader';
import { getQueryClient } from 'restaurant-v3/src/utils/queryClient';
import theme from 'restaurant-v3/src/theme';

const jotaiStore = createStore();

type ClientRootLayoutProps = {
    children?: React.ReactNode;
};

export const Providers = ({ children }: ClientRootLayoutProps) => {
    const queryClient = getQueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AppRouterCacheProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <SnackbarProvider>
                        <JotaiStoreProvider store={jotaiStore}>
                            <Suspense fallback={<QerkoLoader />}>
                                {children}
                            </Suspense>
                        </JotaiStoreProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </AppRouterCacheProvider>
        </QueryClientProvider>
    );
};
