import React from 'react';
import Image from 'next/image';
import Box from '@mui/system/Box';
import QerkoLogoSmall from 'restaurant-v3/src/icons/qerko-logo-small.svg';

export const QerkoLoader: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: '50%',
                bottom: '50%',
                left: '50%',
                right: '50%',
                zIndex: 200,
                '@keyframes loaderAnimation': {
                    '0%%': {
                        transform: 'rotateY(0deg)',
                        transformOrigin: 'center center',
                    },
                    to: {
                        transform: 'rotateY(360deg)',
                        transformOrigin: 'center center',
                    },
                },
            }}
        >
            <Image
                alt="Loading"
                src={QerkoLogoSmall}
                style={{
                    animation: 'loaderAnimation 2s ease-out infinite',
                    height: 100,
                    width: 90,
                }}
            />
        </Box>
    );
};
