'use client';

import { forwardRef } from 'react';
import NextLink from 'next/link';
import { createTheme } from '@mui/material/styles';
import { Inter, Inter_Tight } from 'next/font/google';

const interTight = Inter_Tight({
    subsets: ['latin'],
});

const inter = Inter({
    subsets: ['latin'],
});

declare module '@mui/material/styles' {
    // NOTE(samuel) - color naming from figma

    interface Palette {
        neutral900: Palette['primary'];
        neutral800: Palette['primary'];
        neutral500: Palette['primary'];
        neutral400: Palette['primary'];
        neutral300: Palette['primary'];
        neutral200: Palette['primary'];
        neutral100: Palette['primary'];
        white: Palette['primary'];
        salad500: Palette['primary'];
    }

    interface PaletteOptions {
        neutral900?: PaletteOptions['primary'];
        neutral800?: PaletteOptions['primary'];
        neutral500?: PaletteOptions['primary'];
        neutral400?: PaletteOptions['primary'];
        neutral300?: PaletteOptions['primary'];
        neutral200?: PaletteOptions['primary'];
        neutral100?: PaletteOptions['primary'];
        white?: PaletteOptions['primary'];
        salad500?: PaletteOptions['primary'];
    }
}

// Inspiration for this bs - https://stackoverflow.com/questions/66226576/using-the-material-ui-link-component-with-the-next-js-link-component
const LinkBehaviour = forwardRef(function LinkBehaviour(props, ref) {
    // @ts-expect-error
    return <NextLink ref={ref} {...props} />;
});

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    lineHeight: 1.25,
                    marginLeft: 8,
                    marginRight: 8,
                    // Attempt for smooth scrolling fix, that doesn't work for some reason - https://github.com/vercel/next.js/issues/51721
                    scrollBehavior: 'smooth !important',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehaviour,
            },
            styleOverrides: {
                root: {
                    color: 'inherit',
                    textDecoration: 'none',
                    outline: 0,
                },
            },
        },
    },
    palette: {
        // @ts-expect-error for some reason typescript thinks that neutral900 doesn't belong under mui theme
        neutral900: {
            main: '#141414',
        },
        neutral800: {
            main: '#3d3d3d',
        },
        neutral500: {
            main: '#707070',
        },
        neutral400: {
            main: '#a3a3a3',
        },
        neutral300: {
            main: '#cccccc',
        },
        neutral200: {
            main: '#ebebeb',
        },
        neutral100: {
            main: '#f5f5f5',
        },
        white: {
            main: '#ffffff',
        },
        salad500: {
            main: '#00b579',
        },
    },
    typography: {
        fontFamily: inter.style.fontFamily,
        h1: {
            fontSize: '3rem',
            fontWeight: 400,
            lineHeight: 1.168,
            letterSpacing: '-0.02em',
            textAlign: 'left',
            fontFamily: interTight.style.fontFamily,
        },
        h2: {
            fontSize: '2.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '-0.01em',
            textAlign: 'left',
            fontFamily: interTight.style.fontFamily,
        },
        h3: {
            fontSize: '2rem',
            fontWeight: 500,
            lineHeight: 1.2,
            textAlign: 'left',
            fontFamily: interTight.style.fontFamily,
        },
        h4: {
            fontSize: '1.75rem',
            fontWeight: 500,
            lineHeight: 1.2,
            textAlign: 'left',
            fontFamily: interTight.style.fontFamily,
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: 1.2,
            textAlign: 'left',
            fontFamily: interTight.style.fontFamily,
        },
        h6: {
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: 1.2,
            textAlign: 'left',
            fontFamily: interTight.style.fontFamily,
        },
        body1: {
            fontSize: '1rem', // 16px
        },
        body2: {
            fontSize: '0.875rem', // 14px
        },
        caption: {
            fontSize: '0.6875rem', // 11px
        },
    },
    spacing: (factor: number) => `${8 * factor}px`,
});

const modifySmFontSize = (key: keyof typeof theme.typography, value: number) => {
    // @ts-expect-error
    theme.typography[key] = {
        // @ts-expect-error
        ...theme.typography[key],
        [theme.breakpoints.down('md')]: {
            fontSize: `${value}rem`, // 14px
        },
    };
};

modifySmFontSize('h1', 2.5);
modifySmFontSize('h2', 2);
modifySmFontSize('h3', 1.75);
modifySmFontSize('h4', 1.5);
modifySmFontSize('h5', 1.25);
modifySmFontSize('h6', 1);
modifySmFontSize('body1', 0.875);
modifySmFontSize('body2', 0.6875);
modifySmFontSize('caption', 0.5625);

export default theme;
